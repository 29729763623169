<template>
  <div class="Paymentorder" v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
        <el-tabs v-model="pageInfo.status" @tab-click="handleClick" style="margin-bottom:15px">
            <el-tab-pane label="全部" name="1"></el-tab-pane>
            <el-tab-pane label="待提交" name="153"></el-tab-pane>
            <el-tab-pane label="待审核" name="154"></el-tab-pane>
            <el-tab-pane label="待付款" name="155"></el-tab-pane>
            <el-tab-pane label="已付款" name="156"></el-tab-pane>
            <el-tab-pane label="审核不通过" name="157"></el-tab-pane>
        </el-tabs>
        <el-select clearable v-model="pageInfo.payType" placeholder="付款类型" style="width:150px;margin-right:10px" @change="search">
            <el-option 
            label="全部" 
            value=" "
            ></el-option>
            <el-option
            v-for="item in payTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            >
            </el-option>
        </el-select>
        <el-select clearable v-model="pageInfo.autoCreate" placeholder="数据来源" style="width:150px;margin-right:10px" @change="search">
            <el-option 
            label="全部" 
            value=" "
            ></el-option>
            <el-option
            v-for="item in paySourceList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            >
            </el-option>
        </el-select>
        <el-input v-model="pageInfo.keyword" placeholder="请输入搜索内容" style="width:150px;margin-right:10px" @keyup.enter.native = "search"/>
        <el-button type="primary" icon="el-icon-search" size="small" @click="search">查询</el-button>
        <div style="margin:10px 0;display:flex;justify-content:space-between">
            <div>
                <el-button v-if="pageInfo.status==153" type="primary" @click="infoBox('添加付款单')">添加付款单</el-button>
                <el-button v-if="pageInfo.status==153" type="primary" @click="changeStatus(154)">批量提交审核</el-button>
                <el-button v-if="pageInfo.status==154" type="primary" @click="changeStatus(155)">批量审核通过</el-button>
                <el-button v-if="pageInfo.status==154" type="primary" @click="changeStatus(157)">批量审核不通过</el-button>
                <el-button v-if="pageInfo.status==155" type="primary" @click="changeStatus(156)">标记已支付</el-button>
            </div>
            <TableDialog style="float:right" :tableList='tableList' @setTableHead="setTableHead"/>
        </div>
        <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :cell-style="{ fontSize: '13px', padding: '7px 0' }"
        :header-cell-style="{
            fontSize: '13px',
            padding: '9px 0',
            marginTop: '10px',
            background: '#EBEEF5',
        }"
        border
        @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="60">
            </el-table-column>
            <template v-for="item in tableHead">
                <el-table-column
                :key="item.name"
                :label="item.name"
                :width="item.width"
                :prop="item.prop"
                :sortable="item.sortable"
                v-if="item.prop!='1'&&item.prop!='2'"
                >
                </el-table-column>
                <el-table-column
                :key="item.name"
                :label="item.name"
                :width="item.width"
                :prop="item.prop"
                v-if="item.prop=='2'">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==153">待提交</span>
                        <span v-if="scope.row.status==154">待审核</span>
                        <span v-if="scope.row.status==155">待付款</span>
                        <span v-if="scope.row.status==156">已付款</span>
                        <span v-if="scope.row.status==157">审核不通过</span>
                    </template>
                </el-table-column>
                <el-table-column
                :key="item.name"
                :label="item.name"
                :width="180"
                v-else-if="item.prop=='1'"
                sortable='true'
                >
                    <template slot-scope="scope">
                        <p style="text-align:left">{{scope.row.createBy}}</p>
                        <p style="text-align:left">{{scope.row.createTime}}</p>
                    </template>
                </el-table-column>
            </template>
            <el-table-column
                label="付款人/付款时间"
                :width="180"
                v-if="pageInfo.status==156"
                sortable='true'
                >
                <template slot-scope="scope">
                    <p style="text-align:left">{{scope.row.payBy}}</p>
                    <p style="text-align:left">{{scope.row.payTime}}</p>
                </template>
            </el-table-column>
            <el-table-column
            header-align="center"
            align="center"
            label="操作"
            width="150"
            fixed="right"
            >
                <template slot-scope="scope">
                    <el-button v-if="pageInfo.status==153 || pageInfo.status==154" type="text" @click="infoBox('修改',scope.row.id)">修改</el-button>
                    <el-button v-if="pageInfo.status!=1 && pageInfo.status!=153" type="text" @click="viewDetails(scope.row.id)">审核详情</el-button>
                    <el-tooltip style="margin:0 10px" v-if="pageInfo.status==155 || pageInfo.status==157" :content="scope.row.autoCreate==1?'自动创建，不可撤销':'撤销审核'" placement="top">
                    <el-link :disabled="scope.row.autoCreate==1?true:false"  :type="scope.row.autoCreate==1?'info':'primary'" @click="backout(scope.row.id)">撤销审核</el-link>
                    </el-tooltip>
                    <el-button v-if="pageInfo.status==153" type="text" @click="remove(scope.row.id)">删除</el-button>
                    <el-button v-if="pageInfo.status==1 || pageInfo.status==156" type="text" @click="findDetail(scope.row.id)">查看凭证</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <div class="sum">
            <span>合计</span>
            <span>应付金额：{{sum}}</span>
        </div> -->
        <div style="margin:10px 20px 0 0;text-align:center">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    <!-- 添加付款单 -->
        <el-dialog
        title="付款单"
        :visible.sync="addPayment"
        width="33%"
        :before-close="handleClose">
            <el-form v-loading="dialogLoading" ref="paymentInfo" :rules="paymentInfoRules" :model="paymentInfo" label-width="110px">
                <el-form-item label="付款类型:" prop="payType">
                    <el-select :disabled="disabled" filterable placeholder="请选择付款类型" v-model="paymentInfo.payType" style="width:400px;margin-right:10px">
                        <el-option 
                        :label="item.value" 
                        :value="item.id"
                        v-for="item in payTypeList"
                        :key="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="text" @click="typeManage=true">类型管理</el-button>
                </el-form-item>
                <el-form-item label="付款账户名称:" prop="financeAccountId">
                    <el-select 
                    placeholder="请选择账户名称" 
                    style="width:400px;margin-right:10px"
                    v-model="paymentInfo.financeAccountId">
                        <el-option 
                        @click="getcur()"
                        :label="item.payAccountName" 
                        :value="item.id"
                        v-for="item in payAccountList"
                        :key="item.id"></el-option>
                    </el-select>
                    <el-button type="text" @click="$router.push('/balaPayAccount')">账户管理</el-button>
                </el-form-item>
                <el-form-item label="应付金额:" prop="amountInCurrency">
                    <el-input 
                    :disabled="disabled"
                    placeholder="请填写应付金额" 
                    v-model="paymentInfo.amountInCurrency" 
                    style="width:480px;border:0">
                    <el-button slot="prepend" style="width:80px">{{currency}}</el-button>
                    <el-button slot="append">转RMB金额: {{turnRMB}} 元</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="结算方式:" prop="settlement">
                    <el-select 
                    :disabled="disabled"
                    placeholder="请选择结算方式"  
                    style="width:480px;margin-right:10px"
                    v-model="paymentInfo.settlement">
                        <el-option 
                        :label="item.value" 
                        :value="item.id"
                        v-for="item in settlementTypeList"
                        :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="预计付款时间:" prop="estimatePayDate">
                    <el-date-picker
                    :disabled="disabled"
                    :picker-options="pickerOptions"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="paymentInfo.estimatePayDate"
                    type="date"
                    placeholder="选择日期"
                    style="width:480px;margin-right:10px">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收款账户名称:" prop="collectionAccountName">
                    <el-input :disabled="disabled" v-model="paymentInfo.collectionAccountName" placeholder="请填写收款账户名称" style="width:480px;margin-right:10px"/>
                </el-form-item>
                <el-form-item label="收款账号:" prop="collectionAccount">
                    <el-input :disabled="disabled" placeholder="请输入收款账号" v-model="paymentInfo.collectionAccount" class="input-with-select" style="width:480px">
                        <el-select :disabled="disabled" v-model="paymentInfo.collectionAccountType" slot="prepend" placeholder="收款账户类型" style="width:100px">
                        <el-option 
                        :label="item.value" 
                        :value="item.id"
                        v-for="item in collectionAccountTypeList"
                        :key="item.id"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="关联单号:" prop="relatedBillNo">
                    <el-input :disabled="disabled" placeholder="关联单号" onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')" v-model="paymentInfo.relatedBillNo" style="width:480px;margin-right:10px"/>
                </el-form-item>
                <el-form-item label="备注:" prop="tips">
                    <el-input :disabled="disabled" placeholder="备注" v-model="paymentInfo.tips" style="width:480px;margin-right:10px"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button :loading="bottonLoading" type="primary" @click="sure(addBoxType)">确 定</el-button>
            </span>
            <!-- 类型管理 -->
            <el-dialog
            width="40%"
            title="类型管理"
            :visible.sync="typeManage"
            append-to-body>
                <p style="margin-bottom:10px">所有类型</p>
                <!-- <el-input v-for="(item,index) in payTypeList" :key="item.id" style="width:130px;margin-right:10px" :value="item.value" ></el-input>
                <br> -->
                <div class="box">
                    <div :class="index<=1?'active type':'type'" v-for="(item,index) in payTypeList" :key="item.id" :disabled="index<2">
                        <p v-if="index<=1">{{item.value}}</p>
                        <input v-if="index>1" type="text" v-model="item.value" @change="typeChange(item.id)">
                        <i class="el-icon-close" v-show="index>1" @click="removeType(item.id)"></i>
                    </div>
                    <div class="type" v-show="minshow">
                        <input type="text" v-model="mintype.value" :disabled="disabled" @blur="addType">
                        <i class="el-icon-close" @click="minshow=false"></i>
                    </div>
                </div>
                <el-button type="primary" plain icon="el-icon-plus" size="mini" style="margin-top:10px" @click="append">添加类型</el-button>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="typeManage = false">确 定</el-button>
                </span>
            </el-dialog>
        </el-dialog>


         <!-- 标记已收款上传部分 -->
        <el-dialog
        title="标记已付款"
        :visible.sync="sign"
        width="30%"
        :before-close="closeUpload"
        >
            <span>是否确认标记为已付款?付款完成后，付款账户将减少对应金额。</span>
            <p style="margin:10px 0">
                <i style="color:red;font-style:normal;">*</i>
                <span>上传付款凭证:</span>
            </p>
            <el-upload
            class="upload-demo"
            :action="action"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :on-success="uploading"
            list-type="picture"
            :headers="mytoken">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">支持jpg/jpeg/png/bmp,大小不超过5M</div>
            </el-upload>
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeUpload">取 消</el-button>
                <el-button type="primary" @click="affirm">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
        title="查看凭证"
        :visible.sync="detail"
        width="30%"
        >
            <div class="demo-image__preview">
            <el-image
                v-for="(item,index) in fileList"
                :key='index'
                style="width: 100px; height: 100px"
                :src="item" 
                :preview-src-list="fileList">
            </el-image>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="detail=false">取 消</el-button>
                <el-button type="primary" @click="detail=false">确 定</el-button>
            </span>
        </el-dialog>
        <el-image-viewer
        v-if="showBig"
        :on-close="closeViewer"
        :url-list="bigUrl"
        z-index='10000'
        />
        <el-dialog
        title="批量审核不通过"
        :visible.sync="noPass"
        width="30%"
        >
            <p style="margin-bottom:10px">
                <i style="color:red">*</i>
                不通过原因
            </p>
            <el-input
            type="textarea"
            :rows="7"
            placeholder="请输入内容"
            v-model="uploadInfo.notPassReason">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="noPass = false">取 消</el-button>
                <el-button type="primary" @click="beSureNoPass">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
        title="审核详情"
        :visible.sync="lookdetail"
        width="30%"
        class="lookdetail"
        >
            <div>
                <p>审核人：{{lookdetailInfo.updateBy}}</p>
            </div>
            <div>
                <p>审核状态：{{lookdetailInfo.status}}</p>
            </div>
            <div>
                <p>审核时间：{{lookdetailInfo.update_time}}</p>
            </div>
            <div v-if="pageInfo.status==157">
                <p style="color:red">审核不通过原因：{{lookdetailInfo.notPassReason}}</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="lookdetail = false">取 消</el-button>
                <el-button type="primary" @click="lookdetail = false">确 定</el-button>
            </span>
        </el-dialog>
  </div>
</template>

<script>
import TableDialog from '@/components/tableDialog.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import {
  PageInit,
  start, 
  del, 
  addInit, 
  add, 
  updateInit, 
  financeBillPay, 
  batchUpdate, 
  addtype, 
  delType, 
  checkDetail, 
  ReviewTheDetails
} from '@/api/financialManage/payment.js'
export default {
    components:{
      TableDialog,
      ElImageViewer
    },
    data(){
        let mytoken=window.sessionStorage.getItem('token')
        return {
            loading:false,
            dialogLoading:false,
            showBig:false,
            detail:false,
            sign:false,
            pickerOptions: {
                disabledDate(v) {
                    // return v.getTime() < new Date().getTime() - 8.64e6 ;不包今天
                    return v.getTime() < new Date(new Date(new Date().toLocaleDateString()).getTime())
                },
            },
            importHeaders: {
                 token: mytoken
            },
            noPass:false,
            fileList:[],//上传凭证列表
            // sum:'',合计
            addBoxType:'',
            disabled:false,
            accountManage:false,
            typeManage:false,
            addPayment:false,
            lookdetail:false,
            minshow:false,
            mintype:{id:'',value:''},
            // tab分类
            tabList:[],
            // 新增信息
            type:['银行卡','支付宝','微信','现金'],
            paymentInfo:{
                payType:'',
                financeAccountName:'',
                amountInCurrency:'',
                settlement:'',
                estimatePayDate:'',
                collectionAccountName:'',
                collectionAccount:'',
                // 收款账户类型
                collectionAccountType:'',
                relatedBillNo:'',
                tips:''
            },
            paySourceList:[],//数据来源
            // 付款类型
            payTypeList:[],
            collectionAccountTypeList:[],
            payAccountList:[],
            settlementTypeList:[],
            accountManageInfo:{},
            value: '',
            select:'',
            tableData:[],
            tableHead:[],
            tableList:[
                {name:'付款单号',prop:'payNo',width:'155',fixed:'left'},
                {name:'关联单号',prop:'relatedBillNo'},
                {name:'付款类型',prop:'payTypeName'},
                {name:'付款账户名称',prop:'financeAccountName'},
                {name:'状态',prop:'2'},
                {name:'应付金额RMB',prop:'payableAmount'},
                {name:'币种',prop:'currencyName'},
                {name:'对应币种金额',prop:'amountInCurrency'},
                {name:'收款账户名称',prop:'collectionAccountName'},
                {name:'收款账号',prop:'collectionAccount'},
                {name:'预计付款时间',prop:'estimatePayDate',sortable:true},
                {name:'创建人/创建时间',prop:'1',width:'200'},
                {name:'备注',prop:'tips'},
            ],
            pageInfo:{
                current:'',
                keyword:'',
                status:'1',
                payType:'',
                pageSize:'',
                autoCreate:''
            },
            total:'',
            paymentInfoRules:{
                payType:[{ required: true, message: '请选择付款类型', trigger: 'blur' }],
                amountInCurrency:[{required:true,message:'请输入应付金额',trigger:'blur'}],
                financeAccountId:[{required:true,message:'请选择付款账户名称',trigger:'blur'}],
                settlement:[{required:true,message:'请选择结算方式',trigger:'blur'}],
                estimatePayDate:[{required:true,message:'请选择预计付款时间',trigger:'blur'}],
                collectionAccountName:[{required:true,message:'请输入收款账户名称',trigger:'blur'}],
                collectionAccount:[{required:true,message:'请输入收款账号',trigger:'blur'}],
            },
            multipleSelection: [],
            uploadInfo:{
                ids:[],
                imageIds:[],
                status:'',
                notPassReason:'',
            },
            bigUrl:[],
            lookdetailInfo:{
                update_time:'',
                updateBy:'',
                notPassReason:'',
                status:''
            },
            buttonLoading:false,
            disabled:false,
            UsdRate:'',
            JpyRate:'',
            // 系统创建临时标记应付金额
            RmbRate:''
        };
    },
    created(){
        this.tableHead=this.tableList
        this.getPageInit()
        this.startInit()
        this.addStart()
    },
    computed:{
        action(){
            return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile'
        },
        currency(){
            let unit=''
            this.payAccountList.forEach(item=>{
                if(item.id==this.paymentInfo.financeAccountId){
                    unit=item.currency
                }
            })
            return unit
        },
        turnRMB(){
            if(this.pageInfo.status=='153'){
                if(this.currency=='RMB'){
                    return this.paymentInfo.amountInCurrency
                }
                if(this.currency=='USD'){
                    return Math.round(this.paymentInfo.amountInCurrency * this.UsdRate)/100;
                }
                if(this.currency=='JPY'){
                    return Math.round(this.paymentInfo.amountInCurrency * this.JpyRate)/100
                }
            }
            if(this.pageInfo.status=='154'){
                if(this.currency=='RMB'){
                    this.paymentInfo.amountInCurrency=this.RmbRate
                }
                if(this.currency=='USD'){
                    this.paymentInfo.amountInCurrency = Math.round(this.RmbRate / this.UsdRate*100*100)/100
                }
                if(this.currency=='JPY'){
                    this.paymentInfo.amountInCurrency = Math.round(this.RmbRate / this.JpyRate*100*100)/100
                }
                return this.RmbRate
            }
        }
    },
    methods:{
        closeUpload(){
            this.sign=false
            this.fileList=[]
            this.uploadInfo={
                ids:[],
                imageIds:[],
                status:'',
                notPassReason:'',
            }
        },
        closeViewer(){
            this.showBig = false;
        },
        //点击已上传的文件
        handlePreview(val){
            this.bigUrl=[val.url]
            this.showBig=true
        },
        //上传组件删除钩子
        handleRemove(file,fileList){
            this.fileList=fileList
        },
        uploading(response,file,fileList){
            console.log(response,file,fileList);
            // this.uploadInfo.imageIds.push(file.response.data.fileId)
            this.fileList=fileList
        },
        changeStatus(stu){
            if(this.multipleSelection.length<1){
                this.$message({
                    type:'warning',
                    message:'请选择内容'
                })
                return
            }
            this.uploadInfo.status=stu
            this.fileList=[]
            this.uploadInfo.ids=this.multipleSelection.map(item=>{
                return item.id
            })
            if(stu==156){
                this.sign=true
            }

            // 更改状态部分
            if(stu==155 || stu==154){
                this.uploadInfo.status=stu
                this.$confirm('是否批量提交审核?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    batchUpdate({ids:this.uploadInfo.ids,status:stu}).then(res=>{
                        if(res.data.code==200){
                            this.$message({
                                type: 'success',
                                message: '批量审核通过成功'
                            });
                            this.getPageInit()
                        }else {
                            this.$message({
                                type: 'warning',
                                message: '审核失败'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });          
                });
                
            }

            if(stu==157){
                this.uploadInfo.status=stu
                this.noPass=true
            }
        },
        //确认上传
        affirm(){
            this.uploadInfo.imageIds=this.fileList.map(item=>{
                return item.response.data.fileId
            })
            batchUpdate(this.uploadInfo).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                            type:'success',
                            message:res.data.message
                        })
                        this.fileList=[]
                        this.sign=false
                        this.getPageInit()
                    }else{
                        this.$message({
                            type:'warning',
                            message:res.data.message
                        })
                        return
                    }
                    
                })    
            
        },
        // 查看凭证
        async findDetail(id){
            this.loading=true
            const {data} = await checkDetail(id)
            this.fileList=data.data
            this.loading=false
            this.detail=true
        },
        //确认批量不通过
        beSureNoPass(){
            this.uploadInfo.imageIds=[]
            batchUpdate(this.uploadInfo).then(res=>{
                if(res.data.code==200){
                    this.$message({
                        type:'success',
                        message:res.data.message
                    })
                    this.getPageInit()
                    this.noPass=false
                }else {
                    this.$message({
                        type:'warning',
                        message:res.data.message
                    })
                }
            })
        },
        //撤销审核
        backout(id){
            this.uploadInfo.status=154
            this.uploadInfo.ids=[id]
            this.$confirm('此操作将撤销审核状态，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                batchUpdate({ids:this.uploadInfo.ids,status:this.uploadInfo.status}).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: '撤销成功!'
                        });
                        this.getPageInit()
                    }else {
                        this.$message({
                            type: 'warning',
                            message: '撤销失败!'
                        });
                    }
                    this.loading=false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
            
            this.getPageInit
        },
        //审核详情
        viewDetails(id){
            this.loading=true
            ReviewTheDetails(id).then(res=>{
                this.lookdetail=true
                this.loading=false
                this.lookdetailInfo=res.data.data
            })
        },
        // 点击tab导航
        handleClick(tab, event) {
            this.pageInfo.current=1
            this.pageInfo.status=tab.name
            if(this.pageInfo.status==1){
                this.pageInfo.status=''
                this.pageInfo.keyword=''
                this.pageInfo.payType=''
                this.getPageInit()
                this.pageInfo.status='1'
                return
            }
            this.getPageInit()
        },
        // 弹出框
        infoBox(data,id){
            this.addPayment=true
            this.addBoxType=data
            if(data=='修改'){
                this.dialogLoading=true
                if(this.pageInfo.status==154){
                    this.disabled=true
                }
                // 修改初始化
                updateInit(id).then(res=>{
                    this.paymentInfo=res.data.data
                    if(this.pageInfo.status=='154'){
                        this.RmbRate=res.data.data.payableAmount
                    }
                    this.dialogLoading=false
                })
            }
        },
        //关闭弹出框
        handleClose(){
            this.addPayment=false
            this.disabled=false
            this.paymentInfo={
                payType:'',
                financeAccountName:'',
                payableAmount:'',
                settlement:'',
                estimatePayDate:'',
                collectionAccountName:'',
                collectionAccount:'',
                // 收款账户类型
                collectionAccountType:'',
                relatedBillNo:'',
                tips:''
            }
            this.$refs.paymentInfo.resetFields()
            this.$refs.paymentInfo.clearValidate()
        },
        setTableHead(data){
            this.tableHead=data
        },
        // 表格初始化
        async getPageInit(){
            if(this.pageInfo.status==1){
                this.loading=true
                this.pageInfo.status=''
                PageInit(this.pageInfo).then(res=>{
                    this.tableData=res.data.values
                    this.pageInfo.status='1'
                    this.total=res.data.pageInfo.total
                    this.getSum()
                    this.loading=false
                })
                return
            }
            this.loading=true
            const {data} = await PageInit(this.pageInfo)
            this.tableData=data.values
            this.total=data.pageInfo.total
            this.loading=false
            this.getSum()
        },
        getSum(){
            if(this.tableData.length==0){
                this.sum=0
                return
            }else{
                const price=this.tableData.map(item=>{
                    return item.payableAmount
                })
                this.sum=price.reduce((accumulator, currentValue)=>{
                    return accumulator+currentValue
                })
            }
        },
        // 整表查询
        async startInit(){
            const {data} = await start()
            this.payTypeList=data.data.payTypeList
            this.paySourceList=data.data.paySourceList
            this.tabList=[{id:'1',value:'全部'},data.data.payBillStatusList]
        },
        // 分页器
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getPageInit()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getPageInit()
        },
        // 删除
        remove(id){

            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const {data} =await del(id)
                if(data.code==200){
                    this.$message({
                        message:data.message,
                        type: 'success'
                    })
                    this.getPageInit()
                    if(this.tableData.length=1){
                        this.pageInfo.current=this.pageInfo.current-1
                        this.getPageInit()
                    }
                }else{
                    this.$message({
                        message:'网络超时',
                        type: 'warning'
                    });
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });


            
        },
        async addStart(){
            const {data} = await addInit()
            this.UsdRate=data.data.rateList[0].UsdRate
            this.JpyRate=data.data.rateList[0].JpyRate
            this.collectionAccountTypeList=data.data.collectionAccountTypeList
            this.payAccountList=data.data.payAccountList
            this.settlementTypeList=data.data.settlementTypeList
        },
        sure(data){
            if(this.paymentInfo.collectionAccountType==''){
                this.$message({
                    type:'warning',
                    message:'请选择收款账户类型'
                })
                return
            }
            this.$refs.paymentInfo.validate(valid=>{
                if(valid){
                    this.dialogLoading=true
                    if(data=='添加付款单'){
                        add(this.paymentInfo).then(res=>{
                            this.dialogLoading=false
                            if(res.data.code==200){
                                this.$message({
                                    type:'success',
                                    message:res.data.message
                                })
                                this.handleClose()
                                this.getPageInit()
                            }else {
                                this.$message({
                                    type:'warning',
                                    message:'新增失败'
                                })
                            }
                        })
                    
                    }
                    if(data=='修改'){
                        this.dialogLoading=true
                            financeBillPay(this.paymentInfo).then(res=>{
                                this.dialogLoading=false
                                if(res.data.code==200){
                                    this.$message({
                                        type:'success',
                                        message:'修改成功'
                                    })
                                    this.handleClose()
                                    this.getPageInit()
                                }else {
                                    this.$message({
                                        type:'warning',
                                        message:'修改失败'
                                    })
                                }
                            })
                    }
                }else {
                    console.log('不满足');
                }
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        addType(){
            if(!this.mintype.value){
                this.$message({
                    type:'warning',
                    message:'请输入内容'
                })
                this.minshow=false
                return
            }
            addtype(this.mintype).then(res=>{
                this.minshow=false
                this.startInit()
            })
        },
        append(){
            this.minshow=true
            this.mintype={id:'',value:''}
        },
        removeType(ind){
            this.$confirm('此操作将永久删除该类型, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delType(ind).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }else{
                        this.$message({
                            type: 'warning',
                            message: '删除失败!'
                        });
                    }
                    this.startInit()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });     
                this.startInit()     
            });
        },
        // 类型修改
        typeChange(id){
            this.payTypeList.forEach(item=>{
                if(id==item.id){
                    this.$confirm('此操作将修改此类, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        addtype({id:id,value:item.value}).then(res=>{
                            this.startInit()
                            if(res.data.code==200){
                                this.$message({
                                    type: 'success',
                                    message: '修改成功!'
                                });
                            }else {
                                this.$message({
                                    type:'warning',
                                    message:res.data.message
                                })
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消修改'
                        });
                        this.startInit()          
                    });
                }
            })
        },
        search(){
            this.pageInfo.current=1
            this.getPageInit()
        }
    }
}
</script>

<style scoped lang="scss">
.setting{
    color:#409EFF;
    font-size:25px;
    border:2px solid #409EFF;
    border-radius: 6px;
    overflow: hidden;
    width: 25px;
    height: 25px;
}
.type{
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    width: 107px;
    height: 30px;
    border-radius: 4px;
    border: 1px #E4E7ED solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    input{
        border: none;
        width: 90px;
        outline: none;
        background: transparent;
        font-size: 14px;
        text-align: center;
        color: #606266;
    }
    p{
        text-align: center;
        width: 97px;
    }
    i{
        color: gray;
    }
}
.box{
    display: flex;
    flex-wrap: wrap;
}
.active{
    background: #DCDFE6;
}
.sum{
    background: #E4E7ED;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
}
.demo-image__preview{
    .el-image{
        margin-right: 20px;
    }
}
.lookdetail{
    div{
        margin-bottom: 10px;
    }
}
</style>