import request from '../../utils/request.js'

// 收款单
export function PageInit(params) {
    return request({
        method:'get',
        url:'/api/financeBillPay/listPage',
        params
    })
}

export function start() {
   return request({
       method:'get',
       url:'/api/financeBillPay/pageInit',
   })
}

export function del(id) {
    return request({
        method:'delete',
        url:`/api/financeBillPay/${id}`
    })
}

export function addInit() {
    return request({
        method:'get',
        url:'/api/financeBillPay/addInit'
    });
}

export function add(data) {
    return request({
        method:'post',
        url:'/api/financeBillPay',
        data
    })
}

export function updateInit(id){
    return request({
        method:'get',
        url:`/api/financeBillPay/updateInit/${id}`
    });
}

export function financeBillPay(data){
    return request({
        method:'put',
        url:'/api/financeBillPay',
        data
    });
}

export function batchUpdate(data){
    return request({
        method:'put',
        url:'/api/financeBillPay/batchUpdate',
        data
    })
}

export function addtype(data){
    return request({
        method:'put',
        url:'/api/financeBillPay/addOrUpdateCategory',
        data
    });
}

export function delType(id){
    return request({
        method:'delete',
        url:`/api/financeBillPay/deleteCategory/${id}`
    });
}

export function checkDetail(id) {
    return request({
        method:'get',
        url:`/api/financeBillPay/certificateDetail/${id}`
    })
}

export function ReviewTheDetails(id){
    return request({
        method:'get',
        url:`/api/financeBillPay/getApprovalDetail/${id}`
    })
}